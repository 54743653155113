@import '../../assets/styles/variables';

.Container {
  display: flex;
  flex: 1;
  color: #2f3747;
  font-size: 1rem;
}
.Content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 4;
  position: relative;
  box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  border-right: 1px solid #d6d9dc;
  padding: 15px 0 17px;
  background-color: white;
  transition: all 0.3s ease;
}
.InnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-direction: column;
}
.Title {
  font-size: 1.4rem;
  font-weight: bold;
}
.Button {
  background-color: $secondaryMain !important;
  color: white !important;
  padding: 10px 8px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  font-family: 'Quicksand';
}
