@import '../../assets/styles/variables';

.Main {
  position: absolute;
  display: flex;
  top: 5%;
  left: 10%;
  width: 80%;
  height: 90%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  border-radius: 10px;
  padding: 35px;
  background-color: #fff;
}
.ContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Content {
  font-size: 1.12rem;
  color: $primaryDark;
  text-align: center;
  line-height: 1.7rem;
}

.ButtonContainer {
  background-color: $secondaryXLight !important;
  border-radius: 10px !important;
  font-size: 1.12rem !important;
  margin-top: 1rem !important;
}

.Icon {
  position: absolute;
  bottom: 10%;
  justify-content: center;
  left: 40px;
  & img {
    width: 70%;
    min-height: 100px;
  }
}

.entry-text {
  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: #2f3747;
    text-align: inherit;
  }
}
.entry-text2 {
  p {
    font-size: 1rem;
    font-weight: bold;
    color: #292a29;
    text-align: inherit;
    margin-top: 1.5rem;
  }
}
.entry-text4 {
  p {
    font: size 0.8rem;
    font-weight: 600;
    color: #2f3747;
    text-align: inherit;
  }
}
.entry-text3 {
  p {
    font-size: 1rem;
    font-weight: normal;
    color: #748094;
    text-align: inherit;
  }
}
.plan-item1 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  transition: outline 1.3s;
  background-color: #fff;
  &::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 10%;
    height: 80%;
    border-right: 1px solid #cbcbcb;
  }
}
.plan-item2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 60%;
  transition: outline 1.3s;
  background-color: #fff;
  &::after {
    content: '';
    position: absolute;
    right: 0px;
    top: 10%;
    height: 80%;
    border-right: 1px solid #cbcbcb;
  }
}
.plan-item3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: outline 1.3s;
  background-color: #fff;
}
.plan-annotation-cost {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  border-radius: 10px;
  border: solid 1px #cbcbcb;
  width: 100%;
  min-height: 100%;
  transition: outline 1.3s;
  background-color: #fff;
}
.plan-annotation-cost-text {
  color: #3943b7;
  font-size: 1.5rem;
  font-weight: 500;
}
.plan-item-header {
  font-size: 1.5rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2f3747;
  @media screen and (max-width: 1550px) {
    font-size: 1.8rem;
  }
}
.plan-item-description {
  font-size: 0.8rem;
  margin-top: 1rem;
  // height: 1.2rem;
  // max-height: 1.5rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2f3747;
}
.plan-item-price {
  height: 2.5rem;
  font-size: 1.2rem;
  margin-top: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #cbcbcb;
}
.plan-item-cost {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8rem;
  color: #3943b7;
  height: 2rem;
}
.plan-item-button {
  margin-top: 15px;
  color: #3943b7;
  margin-bottom: 15px;
}

.plan-item-purchase {
  display: flex;
  justify-content: center;
  background-color: #2e3746;
  min-height: 50px;
}
.features {
  display: flex;
  padding: 5px;
  justify-content: space-around;
  border: 1px solid #bdc6ce;
}
.features-description-header {
  font-size: 1.2rem;
  margin-top: 10px;
  font-weight: bold;
  color: #32325d;
}
.bubble {
  width: 90px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5px;
  color: #292a29;
  text-align: center;
  height: 40px;
  border-radius: 10px;
  position: relative;
}
.triangle {
  width: 0;
  position: absolute;
  top: 37px;
  left: 30px;
  border-top: 13px solid #d3d3d3;
  border-left: 13px solid transparent;
  border-right: 13px solid transparent;
}
.tab-content {
  i {
    font-size: 1.8rem;
  }
  p:nth-of-type(1) {
    font-size: 1.7rem;
  }
  p:nth-of-type(2) {
  }
}

.plans {
  display: flex;
  border: solid 1px #cbcbcb;
  border-radius: 10px;
}

.billing-layout {
  @media screen and (max-width: 990px) {
    flex-direction: column-reverse;
  }
  justify-content: center;
}
.mobile-layout {
  @media screen and (max-width: 1600px) {
    flex-direction: column;
  }
}

.box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1120px) {
    flex-direction: column;
  }
}
.info {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 375px;
  border-radius: 5px;
  height: 250px;
  top: -100px;
  right: 200px;
  padding: 20px;
  background-color: #f5f5f7;
  text-align: justify;
  box-shadow: 0px 0px 15px 5px rgb(0 0 0 / 11%);
  outline: 1px solid #3ebfea;
  white-space: normal;
  font-weight: 400;
  z-index: 1;
  @media screen and (max-width: 1600px) {
    right: -400px;
  }
  @media screen and (max-width: 1120px) {
    right: -130px;
    top: -250px;
  }
}
.Card {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 20px;
  font-weight: 500;
  height: 197px;
  color: #fff;
  border-radius: 5%;
  background-color: #63a4ff;
  background-image: linear-gradient(315deg, #91b9f1 0%, #128bfc 74%);
  transition: all 1.5s ease-out;
}
.Text1 {
  margin: 0.5px 0px 4px 85.5px;
  font-size: 1.2rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.Text2 {
  margin: 4px 0px 24px 86.5px;
  font-size: 0.9rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #aeaeae;
}

.featureContainer {
  color: #1d3557;
  font-size: 0.7rem;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  display: flex;
}

.CheckIcon {
  color: #3943b7;
  margin-right: 0.5rem;
  font-size: 1.2rem !important;
}
