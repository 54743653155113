@import '@/assets/styles/variables';

.CardItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8em;
}
.FormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2em;
  gap: 1.6em;
}
.NewCard {
  color: #1d3557;
  font-size: 1rem;
  padding: 0.4em 0.3em;
  font-weight: 600;
  border-radius: 4px;
  align-self: flex-start;

  &:focus {
    outline: none;
  }
}
.CardField {
  font-size: 1rem;
  font-weight: 600;
  color: #1d3557;
}
.CardField2 {
  font-size: 1rem;
  font-weight: 600;
  color: #1d3557;
  width: 100%;
}
.Row {
  display: flex;
  width: 60%;
  gap: 1em;
}
.ButtonWrapper {
  display: flex;
}
.NextButton {
  color: #fff;
  background-color: #3943b7 !important;
  text-transform: none !important;
  padding: 0.6em 2em !important;
  border-radius: 4px !important;
  align-self: flex-end;

  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #a6a6a6 !important;
  }
}
.CardInput {
  border: 1px solid #a6a6a6;
  border-radius: 4px;
  padding: 0.6em 0.4em;

  &:focus {
    border-color: #1976d2;
  }
}

.MuiFormLabel {
  color: $primaryDark !important;
  font-weight: 500 !important;
  font-family: $fontFamily !important;
  font-size: 0.85rem !important;
}
