@import '../../../assets/styles/variables';

.SidebarNav {
  height: 100%;
  width: 13rem;
  z-index: 1;
  position: fixed;
  top: 0;
  box-shadow: 0 6px 26px 0 rgba(34, 55, 123, 0.08);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
  border-radius: 16px; // only top right and bottom right

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.CollapseContainer {
  width: 4rem;
}
.Collapse {
  transform: rotate(180deg);
  margin-top: 1em;
  margin-right: 1em;
  color: $primaryLight;
  display: flex;
  margin-left: auto;
  cursor: pointer;
}
.Logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  & img {
    width: 90%;
    padding: 1.9em;
  }
}
.MenuContainer {
  height: 100%;
}
.Menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  // margin-left: auto;
  // margin-right: auto;
  font-size: 0.8rem;
  color: $primaryLight;
  font-weight: 500;
  margin: 0.75rem;
  gap: 1em;
  & a {
    color: $primaryDark;
    font-size: calc(1em - 1px);
  }
}
.MenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5em;
  // padding: 5.6px 26px;
  width: 100%;
  cursor: pointer;
  padding: 0.75rem;
  &:hover {
    color: $secondaryMain;
    & a {
      color: $secondaryMain;
    }
  }
  & span {
    transition: all 0.3s ease-in-out, color 0s ease;
  }
}
.ActiveMenuItem {
  @extend .MenuItem;
  // color: $secondaryMain !important;
  color: #fff !important;
  background-color: $secondaryMain !important;
  padding: 0.75rem;
  border-radius: 8px;
}
.MenuSettingItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5em;
  font-size: 0.69rem;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: -5px;
  width: 100%;
  cursor: pointer;
  padding: 0.05rem 0.75rem;
  &:hover {
    color: $secondaryMain;
    & a {
      color: $secondaryMain;
    }
  }
  & span {
    transition: all 0.3s ease-in-out, color 0s ease;
  }
}
.CollapsedMenu {
  opacity: 0;
  transform-origin: 0 0;
  transform: rotateY(-90deg);
}
.Icon {
  width: 1rem !important;
  height: 1rem !important;
  // margin-right: 40px;
}
.Freemium {
  height: 50%;
  display: flex;
  align-items: flex-end;
  @media screen and (min-width: 768px) {
    height: 40%;
  }
}
.FreeTrail {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  & .freeTrailPic {
    width: 78%;
    min-height: 100px;
    padding: 2.4em;
    padding-top: 0.8em;
  }
}
.ProfileButton {
  cursor: pointer;
  font-family: 'facto, sans-serif';
  position: relative;
}
.NameText {
  white-space: nowrap;
  margin-left: 10px;
  font-size: 1.1rem;
  font-weight: 500;
}
.ProfileMenuItem {
  & span {
    font-size: 1rem;
    font-family: 'Quicksand';
    color: $secondaryLight;
  }
  & svg {
    color: $secondaryLight;
  }
}
