.Container {
  // margin-left: 80px;
  // margin-top: 30px;
  padding: 0 0px 20px;
  transition: all 0.3s ease-in-out;
}

// html, body {
//   height: 100%;
//   overflow: hidden;
// }

// html {
//   zoom: 75%;
//   transform: scale(0.75);
//   transform-origin: top left;
//   width: 133.33%; /* To prevent horizontal scrollbars */
//   height: 100vh;
// }
