@import '@/assets/styles/variables';

.Container {
  width: 100%;
  background-color: #fff;
  display: flex;
  height: 100%;
}

// Left Side
.Main {
  width: 70%;
  display: flex;
  //  justify-content: center;
  flex-direction: column;
  gap: 3em;
  padding: 2em 0.8em;
}

.StepContainer {
  display: flex;
  flex-direction: column;
  gap: 1.4em;
  padding-right: 4.2em;
  padding-left: 4.2em;
}

.InputRow {
  display: flex;
  width: 100%;
  gap: 2em;
}

.Input {
  background-color: #fff;
}

.selectInput {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.4em 0.4em;
  width: 50%;
  background-color: #fff;
  border-color: #bdb8b8;
  border-radius: 3px;
  font-size: 0.8rem;
  font-weight: 600;
  &:hover {
    outline: none;
    border-color: gray;
  }
  &:focus {
    border: 2px solid #1976d2;
  }
}

.formControl {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 8px 10px;
  width: 100%;
  margin-top: 0.5rem;
  background-color: transparent;
  border-color: #bdb8b8;
  border-radius: 3px;
  font-size: 0.8rem;
  &:hover {
    outline: none;
    border-color: gray;
  }
  &:focus {
    border: 2px solid #1976d2;
  }
}

.MuiFormLabel {
  color: $primaryDark !important;
  font-weight: 500 !important;
  font-family: $fontFamily !important;
  font-size: 0.85rem !important;
}

.NextButton {
  color: #fff;
  background-color: #3943b7 !important;
  text-transform: none !important;
  padding: 0.6em 2em !important;
  border-radius: 4px !important;
  align-self: flex-end;

  &:focus {
    outline: none;
  }
}

.SuccessMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: #1d3557;
}

.ButtonWrapper {
  display: flex;
}

// Right Side
.Rightbar {
  width: 30%;
  background-color: #f5f5f7;
  color: #1d3557;
  display: flex;
  flex-direction: column;
  padding: 1.6em 1.8em;
  border-radius: 10px;
}

.Header2 {
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 0.4em;
  border-bottom: 1px solid #b7bfc7;
}

.Header1 {
  font-size: 1.6rem;
  font-weight: 500;
  padding-bottom: 0.6em;
  padding-top: 0.6em;
  border-bottom: 1px solid #b7bfc7;
  display: flex;
  flex-direction: column;
}

.InnerText {
  font-size: 0.8rem;
  font-weight: normal;
  color: #bbbbbb;
}

.Cost {
  color: #1d3557;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.2em;
  padding-top: 0.6em;
}

.Divider {
  color: #b9bcc9;
}

.AnnotCostText {
  color: #3943b7;
  font-size: 1rem;
  //  white-space: nowrap;
}

.PaymentText {
  padding-bottom: 1.4em;
  font-size: 0.8rem;
  font-weight: 500;
  color: #828d9b;
}

.PaymentInfo {
  border-top: 1px solid #b7bfc7;
  padding-top: 0.8em;
  display: flex;
  gap: 0.6em;
  color: #1d3557;
  font-weight: normal;
}

.entry-text2 {
  p {
    font-size: 1rem;
    font-weight: bold;
    color: #292a29;
    text-align: inherit;
    margin-top: 4rem;
  }
}
.entry-text4 {
  p {
    font: size 0.8rem;
    font-weight: 600;
    color: #2f3747;
    text-align: inherit;
  }
}

@media screen and (max-width: 1200px) {
  .Header2 {
    font-size: 1rem;
  }
  .Header1 {
    font-size: 1.6rem;
  }
  .InnerText {
    font-size: 0.6rem;
  }
  .Cost {
    font-size: 1.2rem;
  }
  .AnnotCostText {
    font-size: 0.8rem;
  }
  .PaymentText {
    font-size: 0.6rem;
  }
}
