.Card {
  display: flex;
  flex-direction: column;
  width: 45%;
}
.Address {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 350px;
}
label {
  font-weight: 400;
  letter-spacing: 0.025em;
}
label .Label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}
#card_name{
  width: 100%;
  font-size: 1.1rem;
}
#card_button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

#card_button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

/* #threeD_secure {
  width: 600px;
  height: 400px;
} */

label input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  box-shadow: 'none';
  /* outline: 0; */
  border-radius: 4px;
  background: white;
  border: 1px solid #b7bfc7;
}
#billing
.StripeElement {
  display: block;
  margin: 0;
  padding: 0;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 0px 0px;
  font-family: "Source Code Pro", monospace;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}
label input::placeholder {
  color: #aab7c4;
}
#billing{
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
}
label input:focus,
.StripeElement--focus {
  box-shadow: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}
